/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { cardOrderTranslation, forms, modals } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { useModalController } from '../../../hooks/useModalController'
import { useCallbackPrompt } from './useCallbackPrompt'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'

interface LeaveOrderPromptState {
  showPrompt: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  buttons: PopupButtonModel[]
  title: string
  description: string
  handleCancelNavigation: () => void
}

export function useLeaveOrderPrompt(): LeaveOrderPromptState {
  const { t } = useTranslation()
  const {
    clearOrders,
    orders,
    orderExcel,
    clearOrderExcel,
    clearOrderCheckout,
    leaveOrderEnabled,
    setLeaveOrderEnabled,
    clearCardOrderDuplicates,
    clearCardOrderRecharges,
    clearCardOrderUnload,
  } = useCardOrder()
  const { showPrompt, confirmNavigation, cancelNavigation, showCancelModal } =
    useCallbackPrompt(leaveOrderEnabled)
  const { title, description, buttons, setShow, setDescription, setTitle, setButtons } =
    useModalController()

  const handleConfirmLeave = (): void => {
    confirmNavigation()
    clearOrders()
    clearOrderExcel()
    clearOrderCheckout()
    clearCardOrderDuplicates()
    clearCardOrderRecharges()
    clearCardOrderUnload()
  }

  const handleCancelNavigation = () => {
    cancelNavigation()
  }

  const initModal = (): void => {
    setButtons([
      {
        title: t(
          showCancelModal ? forms.buttons.cancel : modals.leaveOrder.button.cancel
        ),
        category: 'tertiary',
        onClick: handleCancelNavigation,
        size: 'large',
      },
      {
        title: t(
          showCancelModal ? forms.buttons.discard : modals.leaveOrder.button.confirm
        ),
        category: 'primary',
        onClick: handleConfirmLeave,
        size: 'large',
      },
    ])
    setTitle(
      t(
        showCancelModal
          ? cardOrderTranslation.cardOrderHome.modalDismissTitle
          : modals.leaveOrder.title
      )
    )
    setDescription(
      t(
        showCancelModal
          ? cardOrderTranslation.cardOrderHome.modalDismissBody
          : modals.leaveOrder.description
      )
    )
  }

  useEffect(() => {
    initModal()
  }, [showCancelModal])

  useEffect(() => {
    initModal()

    if (orders.length > 0 || orderExcel?.abstractCardOrder?.resumeData?.length > 0) {
      setLeaveOrderEnabled(true)
    }
  }, [])

  return { showPrompt, setShow, title, description, buttons, handleCancelNavigation }
}
