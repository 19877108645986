import { useEffect, useState } from 'react'
import { UseFormReturn, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { number, object } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { yupResolver } from '@hookform/resolvers/yup'
import { employeesService } from 'src/Flex/Employees/application'
import { forms, spendingRuleFormTranslation } from 'src/domain/translations'
import { documentType } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { DeleteEmployeeDTO, EmployeeConfigurationModel } from 'src/Flex/Employees/domain'
import {
  ageValidationSchema,
  documentSchema,
  nonEmptyChoiceSchema,
  nonEmptyNumericChoiceSchema,
  nonEmptyPositiveNumberSchema,
  nonEmptyStringSchema,
  phoneSchema,
  positiveNumberSchema,
  requiredCheckboxSchema,
  stringSchema,
  useMetaResponseHandler,
} from 'src/Flex/Shared/ui/Form'

import { publishEvent } from 'src/Flex/Shared/ui/Events/helpers'
import { Events as EmployeesEvents } from 'src/Flex/Employees/domain'
import { ConvertDateToISOPlusOne, formatDateToDDMMYYYY } from 'src/core/helpers'
import { flexEmployeesTranslations } from '../../translations'

export interface EmployeeForm {
  userFirstName: string
  userLastName: string
  userLastName2: string
  documentType: documentType
  document: string
  birthday: string
  email: string
  telephone: string
  costCenter: string
  employeeNumber: string
  collectiveId: string
  socialSecurityNumber: string
  workingPercentage: number
  annualGrossSalary: number
  salaryInKind: number
  irpf: number
  sendWelcomeEmail?: 'true' | 'false'
  acceptConditions: boolean
  deliverySiteId: number
  sendData?: unknown
}

export interface IUseConfigureEmployeeController {
  model: EmployeeConfigurationModel | undefined
  form: UseFormReturn<EmployeeForm>
  create: (data: EmployeeForm) => Promise<string>
  save: (data: EmployeeForm, id: string) => Promise<boolean>
  load: (id: string) => Promise<void>
  remove: (employeeId: string) => Promise<DeleteEmployeeDTO>
}

export interface IUseConfigureEmployeeControllerParams {
  id?: string
}

const schema = (t: TFunction<'undefined', null>, id: string | undefined) => {
  const o: ObjectShape = {
    userFirstName: nonEmptyStringSchema(t, 20),
    userLastName: nonEmptyStringSchema(t, 20),
    userLastName2: stringSchema(t, 20),
    documentType: nonEmptyNumericChoiceSchema(t, [
      documentType.DniNie,
      documentType.Passport,
    ]),
    document: documentSchema(t),
    birthday: ageValidationSchema(t, undefined, 16),
    email: nonEmptyStringSchema(t, 75).email(t(forms.errors.invalidFormat)),
    telephone: phoneSchema(t),
    costCenter: stringSchema(t),
    employeeNumber: stringSchema(t),
    collectiveId: nonEmptyStringSchema(t),
    socialSecurityNumber: stringSchema(t),
    workingPercentage: nonEmptyPositiveNumberSchema(t, 100, false).test(
      'decimal-max-2-places',
      t(spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals),
      value => {
        const isNullable = value === null || value === undefined
        if (isNullable) {
          return true
        }
        const regex = /^\d+(\.\d{1,2})?$/
        return regex.test(value?.toString())
      }
    ),
    annualGrossSalary: nonEmptyPositiveNumberSchema(t),
    salaryInKind: positiveNumberSchema(t),
    irpf: nonEmptyPositiveNumberSchema(t, 100, false, 0),
    acceptConditions: requiredCheckboxSchema(t),
    deliverySiteId: number()
      .typeError(t(forms.errors.fieldRequired))
      .required(t(forms.errors.fieldRequired))
      .test(
        'is-not-empty',
        t(forms.errors.notZero),
        value => value !== undefined && value > 0
      ),
  }

  if (!id) {
    o.sendWelcomeEmail = nonEmptyChoiceSchema(t, ['true', 'false'])
  }

  return object(o)
}

const convertToApi = (data: EmployeeForm, id?: string): EmployeeConfigurationModel => {
  delete data['sendData']

  return {
    ...data,
    salaryInKind: data.salaryInKind || 0,
    sendWelcomeEmail: id !== undefined ? undefined : data.sendWelcomeEmail === 'true',
    birthday: data.birthday ? new Date(data.birthday) : undefined,
    id,
  }
}

export const useConfigureEmployeeController = ({
  id,
}: IUseConfigureEmployeeControllerParams): IUseConfigureEmployeeController => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const [model, setModel] = useState<EmployeeConfigurationModel>()
  const { handleMetaResponse } = useMetaResponseHandler()

  useEffect(() => {
    if (id) {
      load(id)
    }
  }, [id])

  const form = useForm<EmployeeForm>({
    resolver: yupResolver(schema(t, id)),
    mode: 'onChange',
  })

  const create = async (data: EmployeeForm): Promise<string> => {
    startLoading()

    const response = await employeesService().CreateEmployee(convertToApi(data))

    stopLoading()

    if (handleMetaResponse(response?.meta, form)) {
      publishEvent(EmployeesEvents.CREATE_NEW_EMPLOYEE)
      return response.data || ''
    }

    return ''
  }

  const save = async (data: EmployeeForm, id: string): Promise<boolean> => {
    startLoading()

    delete data['sendData']

    const response = await employeesService().SaveEmployee(convertToApi(data, id))

    stopLoading()

    return handleMetaResponse(response?.meta, form)
  }

  const load = async (id: string): Promise<void> => {
    startLoading()

    const response = await employeesService().GetEmployeeConfiguration(id)

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setModel(response.data)

      form.reset({
        ...form.getValues(),
        userFirstName: response.data.userFirstName,
        userLastName: response.data.userLastName,
        userLastName2: response.data.userLastName2,
        documentType: response.data.documentType,
        document: response.data.document,
        birthday: formatDateToDDMMYYYY(response.data.birthday).substring(0, 10) ?? '',
        email: response.data.email,
        telephone: response.data.telephone,
        costCenter: response.data.costCenter,
        employeeNumber: response.data.employeeNumber,
        collectiveId: response.data.collectiveId,
        socialSecurityNumber: response.data.socialSecurityNumber,
        workingPercentage: response.data.workingPercentage,
        annualGrossSalary: response.data.annualGrossSalary,
        salaryInKind: response.data.salaryInKind,
        irpf: response.data.irpf,
        acceptConditions: false,
        deliverySiteId: response.data.deliverySiteId,
      })
    }
  }

  const remove = async (employeeId: string): Promise<DeleteEmployeeDTO> => {
    const response = await employeesService().DeleteEmployee(employeeId)
    handleMetaResponse(response?.meta, undefined, {
      successMessage: t(flexEmployeesTranslations.delete.success),
    })
    return response
  }

  return {
    model,
    form,
    create,
    load,
    save,
    remove,
  }
}
