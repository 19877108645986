import { OreIconButton, SvgBin } from '@runroom/oreneta'
import React from 'react'

interface Props {
  employeeId: string
  productId: string
  disabled?: boolean
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
}

export const ConfigureEmployeeProductDeleteAction = ({
  employeeId,
  productId,
  deleteProductEmployee,
}: Props) => {
  return (
    <div className="custom-row--action">
      {/* <ConfigureEmployeeProductDeleteButton
        employeeId={employeeId}
        productId={productId}
        deleteProductEmployee={deleteProductEmployee}
      /> */}
    </div>
  )
}

export const ConfigureEmployeeProductDeleteButton = ({
  employeeId,
  productId,
  disabled,
  deleteProductEmployee,
}: Props) => {
  return (
    <></>
    // <OreIconButton
    //   as="button"
    //   disabled={!!disabled}
    //   icon={<SvgBin />}
    //   onClick={() => deleteProductEmployee(employeeId, productId)}
    // />
  )
}
