import React from 'react'
import { NotificationSeverity, lastCardStatus } from 'src/domain/enum'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useTranslation } from 'react-i18next'
import { isCardStatus } from 'src/core/helpers'
import { EmployeeProfilePill } from '../employeesTR/employeeProfile/atom'
import { DeleteClientEmployeeResponse } from 'src/domain/models/cardOrder/DeleteClientEmployees'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

export interface State {
  getCardStatusPill(currentStatus: number): JSX.Element
  addSuccessNotification: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void
}

export const useEmployeesSharedController = (): State => {
  const { t } = useTranslation()
  const { addNotification } = useNotification()

  const addSuccessNotification = (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ): void => {
    if (!response.dischargeOrderLines || response.dischargeOrderLines.length <= 0) return
    let orderNumbers = ''
    response.dischargeOrderLines.forEach(order => {
      if (order.orderId > 0) {
        orderNumbers += '#' + order.orderId + ', '
      }
    })
    if (orderNumbers !== '') {
      addNotification(
        !isDeletingProduct
          ? t(employeesTranslation.notification.deleteEmployee.successAndUnload) +
              orderNumbers.slice(0, -2)
          : t(employeesTranslation.notification.deleteProduct.successAndUnload) +
              orderNumbers.slice(0, -2),
        NotificationSeverity.success
      )
    } else {
      addNotification(
        !isDeletingProduct
          ? t(employeesTranslation.notification.deleteEmployee.success)
          : t(employeesTranslation.notification.deleteProduct.success),
        NotificationSeverity.success
      )
    }
  }

  const getCardStatusPill = (currentStatus: number): JSX.Element => {
    switch (currentStatus) {
      case lastCardStatus.logicDestrucction:
        return (
          <EmployeeProfilePill tone="grey">
            {t(employeesTranslation.profile.card.status.logicDestrucction)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.requested:
        return (
          <EmployeeProfilePill tone="cobalt-600">
            {t(employeesTranslation.profile.card.status.requested)}
          </EmployeeProfilePill>
        )
      case isCardStatus(lastCardStatus.sent, currentStatus):
        return (
          <EmployeeProfilePill tone="cobalt-600">
            {t(employeesTranslation.profile.card.status.sent)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.rejected:
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.card.status.rejected)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.confirmed:
        return (
          <EmployeeProfilePill tone="success-600">
            {t(employeesTranslation.profile.card.status.confirmed)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.activationResquested:
        return (
          <EmployeeProfilePill tone="warning-600">
            {t(employeesTranslation.profile.card.status.activationResquested)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.active:
        return (
          <EmployeeProfilePill tone="success-600">
            {t(employeesTranslation.profile.card.status.active)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.lockRequested:
        return (
          <EmployeeProfilePill tone="warning-600">
            {t(employeesTranslation.profile.card.status.lockRequested)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.locked:
        return (
          <EmployeeProfilePill tone="grey">
            {t(employeesTranslation.profile.card.status.locked)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.unlockRequested:
        return (
          <EmployeeProfilePill tone="warning-600">
            {t(employeesTranslation.profile.card.status.unlockRequested)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.cancelRequested:
        return (
          <EmployeeProfilePill tone="warning-600">
            {t(employeesTranslation.profile.card.status.cancelRequested)}
          </EmployeeProfilePill>
        )
      case isCardStatus(lastCardStatus.cancelled, currentStatus):
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.card.status.cancelled)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.activationRejected:
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.card.status.activationRejected)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.lockRejected:
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.card.status.lockRejected)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.unlockRejected:
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.card.status.unlockRejected)}
          </EmployeeProfilePill>
        )
      case lastCardStatus.cancellationRejected:
        return (
          <EmployeeProfilePill tone="error-600">
            {t(employeesTranslation.profile.card.status.cancellationRejected)}
          </EmployeeProfilePill>
        )
      default:
        return <></>
    }
  }

  return { getCardStatusPill, addSuccessNotification }
}
