interface IProductsTranslateKeys {
  ticketRestaurant: string
  ticketTransporte: string
  ticketGuarderia: string
  training: string
  ssi: string
  sse: string
}

export const productsTranslate: IProductsTranslateKeys = {
  ticketRestaurant: 'edenred.products.ticketRestaurant',
  ticketTransporte: 'edenred.products.ticketTransporte',
  ticketGuarderia: 'edenred.products.ticketGuarderia',
  training: 'edenred.products.training',
  ssi: 'edenred.products.ssi',
  sse: 'edenred.products.sse',
}
