import {
  OreButton,
  OreCardActions,
  OreCardContainer,
  OreMessageBar,
  OreHeading,
  OreIcon,
  OreStack,
  OreText,
  SvgUnsubscribeProduct,
  SvgWarning,
} from '@runroom/oreneta'

import { useEmployeeProfileController } from '../hooks'
import {
  EmployeeProfileCardDataTR,
  EmployeeProfileFormLabelsTR,
  EmployeeProfileFormTR,
} from '../molecule'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useTranslation } from 'react-i18next'
import { Divider } from 'src/presentation/layout'
import { is100PercentFlex, is100PercentSub, isMixClient } from 'src/core/services'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { Modal, Table } from 'src/presentation/components/Edenred'
import { EmployeesWithLastCard } from 'src/domain/models'
import { DeleteClientEmployeeResponse } from 'src/domain/models/cardOrder/DeleteClientEmployees'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  employeeData: EmployeesWithLastCard
  isEmployeeFlex: boolean
  productCodes?: number[]
  addSuccessNotification: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void
  setProductCodes: Dispatch<SetStateAction<number[] | undefined>>
}

export const EmployeeProfileProductCardTR = ({
  employeeData,
  isEmployeeFlex,
  productCodes,
  addSuccessNotification,
  setProductCodes,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { permissionTags } = useAuth()
  const {
    header,
    selector,
    rows,
    setRows,
    setShowPopupDeleteProduct,
    popupShowDeleteProduct,
    popupTitleDeleteProduct,
    popupButtonsDeleteProduct,
    onClickDeleteProduct,
  } = useEmployeeProfileController(
    employeeData,
    addSuccessNotification,
    productCodes,
    setProductCodes
  )

  if (!employeeData) {
    return <></>
  }

  return (
    <div role="tabpanel" className="mt-3">
      <OreCardContainer>
        <div className="p-105">
          <EmployeeProfileCardDataTR employeeData={employeeData} />
          <Divider marginTop="1rem" marginBottom="2rem" />
          <Table
            header={header}
            selector={selector}
            rows={rows}
            setRows={setRows}
            tableBodyValign="middle"
          />
          {isEmployeeFlex ? (
            <EmployeeProfileFormLabelsTR employeeData={employeeData} />
          ) : (
            <EmployeeProfileFormTR
              employeeData={employeeData}
              isEmployeeFlex={isEmployeeFlex}
            />
          )}
        </div>
        {productCodes && productCodes.length > 1 && (
          <OreCardActions>
            <OreStack
              direction="row"
              space="5xlarge"
              placeItems="center"
              placeContent="space-between">
              <OreButton
                size="small"
                category="tertiary"
                icon={<SvgUnsubscribeProduct />}
                onClick={() => onClickDeleteProduct(+employeeData.userId)}>
                {t(employeesTranslation.profile.card.removeProduct)}
              </OreButton>
            </OreStack>
          </OreCardActions>
        )}
      </OreCardContainer>
      <Modal
        handleOnClose={setShowPopupDeleteProduct}
        open={popupShowDeleteProduct}
        buttons={popupButtonsDeleteProduct}
        closeIcon={false}>
        <>
          <OreStack placeItems="start" direction="row" space="small">
            <OreIcon
              size="small"
              tone="neutral"
              icon={<SvgWarning color="var(--color-warning-500)" />}
            />
            <OreHeading size="headline-lg">{popupTitleDeleteProduct}</OreHeading>
          </OreStack>
          <OreStack placeItems="start" direction="column" space="small">
            <OreText align="left" as="p" size="text-md" tone="neutral">
              {t(employeesTranslation.table.deleteProductModal.description1)}
            </OreText>
            <OreText align="left" as="p" size="text-md" tone="neutral">
              {t(employeesTranslation.table.deleteProductModal.description2)}
            </OreText>
            <OreText align="left" as="p" size="text-md" tone="neutral" bold={true}>
              {t(employeesTranslation.table.deleteProductModal.description3)}
            </OreText>
            <OreText align="left" as="p" bold size="text-md" tone="neutral">
              {t(employeesTranslation.table.deleteProductModal.confirmDeleteDescription)}
            </OreText>
          </OreStack>
        </>
      </Modal>
    </div>
  )
}
