import {
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreIcon,
  SvgRestaurant,
} from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { cardOrderFormTranslation, productsTranslate } from 'src/domain/translations'
import { TicketRestaurantProductModel } from 'src/Flex/Employees/domain/EmployeeProductListModel'
import { flexEmployeesTranslations } from '../../translations'
import React from 'react'
import { ConfigureEmployeeProductRowItem } from '../atoms/ConfigureEmployeeProductRowItem'
import { MonthsAndAmountsItem } from '../atoms/MonthsAndAmountsItem'
import { ConfigureEmployeeProductDeleteAction } from '../atoms/ConfigureEmployeeProductDeleteAction'

interface IProps {
  employeeId: string
  product: TicketRestaurantProductModel
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
}

export const ConfigureEmployeeTRRow = ({
  employeeId,
  product,
  deleteProductEmployee,
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <OreTableRow valign="middle" hover={false}>
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack
            space="xsmall"
            placeContent="center"
            placeItems="center"
            direction="row">
            <OreIcon size="extra-small" icon={<SvgRestaurant />} tone="restaurant" />
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {t(productsTranslate.ticketRestaurant)}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell colSpan={2}>
        <OreStack direction="row" placeContent="space-between">
          <ul>
            {product.isVirtual != undefined && (
              <ConfigureEmployeeProductRowItem
                label={t(
                  flexEmployeesTranslations.configure.products.table.details.cardType
                )}
                value={
                  product.isVirtual
                    ? t(cardOrderFormTranslation.cardDetail.virtual)
                    : t(cardOrderFormTranslation.cardDetail.physical)
                }
              />
            )}
            {product.contractDate && (
              <ConfigureEmployeeProductRowItem
                label={t(
                  flexEmployeesTranslations.configure.products.table.details.contractDate
                )}
                value={product.contractDate}
              />
            )}
            {product.startDate && (
              <ConfigureEmployeeProductRowItem
                label={t(
                  flexEmployeesTranslations.configure.products.table.details.startDate
                )}
                value={product.startDate}
              />
            )}
            {product.monthsAndAmounts && product.monthsAndAmounts.length > 0 && (
              <MonthsAndAmountsItem
                label={t(
                  flexEmployeesTranslations.configure.products.table.details
                    .monthsAndAmounts
                )}
                items={product.monthsAndAmounts}
              />
            )}
          </ul>
          <ConfigureEmployeeProductDeleteAction
            employeeId={employeeId}
            productId={product.id}
            deleteProductEmployee={deleteProductEmployee}
          />
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
