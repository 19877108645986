import { OreText } from '@runroom/oreneta'
import React from 'react'

interface Props {
  label: string
  items: { month: string; amount: number }[]
}

export const MonthsAndAmountsItem = ({ label, items }: Props) => (
  <li>
    <OreText size="text-sm">
      {label}
      {items
        .filter(item => item.amount > 0)
        .map((item, index) => (
          <React.Fragment key={index}>
            <span className="bold">{item.month}</span> ({item.amount}€)
            {index < items.length - 1 && ', '}
          </React.Fragment>
        ))}
    </OreText>
  </li>
)
