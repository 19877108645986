import { EmployeeProfileTemplate } from '../../Templates'
import {
  useDeleteEmployeeController,
  useEmployeeProfilePageController,
  useEmployeesSharedController,
} from './hooks'
import { EmployeeProfileContainer } from './organism'

export const EmployeeProfilePage = (): JSX.Element => {
  const { addSuccessNotification } = useEmployeesSharedController()
  const deleteEmployeeProps = useDeleteEmployeeController(addSuccessNotification)
  const { employeeAvatar } = useEmployeeProfilePageController()

  if (!employeeAvatar) {
    return <></>
  }

  return (
    <EmployeeProfileTemplate
      employeeNumber={employeeAvatar.employeeNumber}
      userName={employeeAvatar.userName}
      deleteEmployeeProps={deleteEmployeeProps}
      organism={
        <EmployeeProfileContainer addSuccessNotification={addSuccessNotification} />
      }
    />
  )
}
