import { useEffect, useState } from 'react'
import { FilterResultModel, SearchParamsModel } from 'src/Flex/Shared/domain'
import { HttpModel, PaginationModel } from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useLocation } from 'react-router-dom'
import { MetaStatusCodes } from 'src/domain/enum'
import { Console } from 'console'

interface IUseBackendTable<T extends object> {
  loadItems: () => Promise<boolean>
  removeItem: (item: T) => Promise<boolean>
  actionOnItem: (
    action: (item: T) => Promise<HttpModel<unknown>>,
    item: T
  ) => Promise<boolean>
  itemsPerPage: number
  items: T[]
  page: number
  count: number
  onPageChange: (page: number) => void
  search: (search: string, filters?: FilterResultModel[]) => void
  searchParameters: SearchParamsModel
}

export type LoadResponse<T extends object> = {
  success: boolean
  items: T[]
  count: number
}

export const useBackendTable = <T extends object>(
  load: (
    page: number,
    itemsPerPage: number,
    searchParameters: SearchParamsModel
  ) => Promise<HttpModel<PaginationModel<T>>>,
  remove?: (item: T) => Promise<HttpModel<unknown>>,
  itemsPerPage = 8
): IUseBackendTable<T> => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const location = useLocation()
  const [items, setItems] = useState<T[]>([])
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(0)

  const [searchParameters, setSearchParameters] = useState<SearchParamsModel>({
    search: '',
    filters: [],
  })

  const loadItems = async (): Promise<boolean> => {
    if (location.search !== '' && searchParameters.filters.length > 0) {
      return executeLoad()
    }
    if (location.search === '') {
      return executeLoad()
    }
    if (location.search === '?adherenceTab=1' && searchParameters.filters.length === 0) {
      return executeLoad()
    }
    if (location.search === '?isWelcomeEmailSent=false') {
      return executeLoad()
    }
    return false
  }

  const executeLoad = async (): Promise<boolean> => {
    startLoading()
    const response = await load(page, itemsPerPage, searchParameters)
    const valid = handleMetaResponse(response?.meta, undefined, { notifySuccess: false })
    if (valid) {
      setItems(response.data?.rows ?? [])
      setCount(response.data?.totalRows ?? 0)
    }
    stopLoading()
    return valid
  }

  const removeItem = async (item: T): Promise<boolean> => {
    if (!remove) return false

    startLoading()

    const response = await remove(item)

    stopLoading()

    const valid = handleMetaResponse(response?.meta)

    if (valid) {
      if (items.length === 1 && page > 1) {
        setPage(page => page - 1)
      } else {
        return await loadItems()
      }
    }

    return valid
  }

  const actionOnItem = async (
    action: (item: T) => Promise<HttpModel<unknown>>,
    item: T
  ): Promise<boolean> => {
    startLoading()

    const response = await action(item)

    stopLoading()

    const valid = handleMetaResponse(response?.meta)

    if (valid) {
      return await loadItems()
    }

    return valid
  }

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const search = async (search: string, filters?: FilterResultModel[]): Promise<void> => {
    setSearchParameters({ search, filters: filters || [] })
    setPage(1)
  }

  useEffect(() => {
    loadItems()
  }, [page, searchParameters])

  return {
    loadItems,
    removeItem,
    actionOnItem,
    itemsPerPage,
    items,
    page,
    count,
    onPageChange,
    search,
    searchParameters,
  }
}
