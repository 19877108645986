import { useState } from 'react'
import { cardOrderTranslation } from '../../../../domain/translations/cardOrder/cardOrder'
import { CardOrderHomeTemplate } from '../../../Templates'
import {
  CardOrderHomeFooter,
  CardOrderHomeTable,
  CardOrderHomeTableEmpty,
} from './molecules'
import { CardOrderHomeForm } from './organism'
import { MessagesModel } from 'src/domain/models'
import { OrderValidationErrorStep } from 'src/Flex/Orders/ui/order-validation'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export const CardOrderHomePage = (): JSX.Element => {
  const navigate = useNavigate()
  const [errorData, setErrorData] = useState<MessagesModel[]>()
  const [excelError, setExcelError] = useState<string>('')
  const backToHome = (): void => {
    setErrorData(undefined)
    setExcelError('')
    navigate(navigationRoutes.cardOrderHome)
  }
  return (
    <CardOrderHomeTemplate
      title={cardOrderTranslation.cardOrderHome.title}
      elementsnoOrders={[<CardOrderHomeTableEmpty />, <CardOrderHomeFooter />]}
      elementsWithOrders={[
        <CardOrderHomeTable />,
        <CardOrderHomeForm setErrorData={setErrorData} setExcelError={setExcelError} />,
      ]}
      errorData={errorData}
      excelError={excelError}
      backToHome={backToHome}
    />
  )
}
