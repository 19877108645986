import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import { edenredProducts } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { EmployeesService } from 'src/core/services/employeesService'
import { EmployeesNurseryRequest } from 'src/domain/models'
import { useNavigate } from 'react-router-dom'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import {
  EmployeesTGDOrderModel,
  TGDChildModel,
  TGDEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { tgdOrderType } from 'src/domain/enum/tgdOrder'
import { OreCheckbox } from '@runroom/oreneta'
import { TGDEmployeesNurseryMap } from 'src/core/services/tgdOrderService'
import { createOrderId } from 'src/core/helpers'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export interface TGDMultipleEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTGD: EmployeesTGDOrderModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  search(filter: EmployeesNurseryRequest): void
  onEnter: (event: KeyboardEvent<HTMLInputElement>) => void
  form: UseFormReturn<EmployeesNurseryRequest, any>
  onSearch: () => void
  addChilds(employees?: EmployeesTGDOrderModel[]): void
  selectedEmployees: EmployeesTGDOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesTGDOrderModel[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesTGDOrderModel
  ): void
  isSelected(employee: EmployeesTGDOrderModel): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
}

export const useTGDOrderSelectMultipleEmployees = (): TGDMultipleEmployeeState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [employeesTGD, setEmployeesTGD] = useState<EmployeesTGDOrderModel[]>([])
  const { setTGDEmployeesToConfig, getTGDEmployeesToConfig, tgdOrders } = useTGDOrder()
  const navigate = useNavigate()
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeesTGDOrderModel[]>(
    getTGDEmployeesToConfig()
  )
  const [showSelected, setShowSelected] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [sortByEmployeeNumberAsc, setSortByEmployeeNumberAsc] = useState<boolean>(true)

  const defaultValues: EmployeesNurseryRequest = {
    productCode: edenredProducts.ticketGuarderia,
    userNameOrDNIFilter: '',
    paginationModel: {
      orderByField: 'employeeNumber',
      sortDescending: !sortByEmployeeNumberAsc,
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  }

  const getDiffBetweenEmployeeArrays = (
    array1: EmployeesTGDOrderModel[],
    array2: EmployeesTGDOrderModel[]
  ): EmployeesTGDOrderModel[] => {
    let result: EmployeesTGDOrderModel[] = []
    result = array1.filter(emp1 => {
      return !array2.some(emp2 => {
        return emp1.userSon.userId === emp2.userSon.userId
      })
    })
    return result
  }

  const [query, setQuery] = useState<EmployeesNurseryRequest>({ ...defaultValues })

  const header: HeaderModel = {
    headerElement: (
      <OreCheckbox
        name="check"
        checked={
          selectAll ||
          getDiffBetweenEmployeeArrays(employeesTGD, selectedEmployees).length <= 0
        }
        onChange={event => {
          onSelectAllEmployees(event)
        }}
        disabled={showSelected}
      />
    ),
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      {
        label: t(employeesTranslation.table.header.employeeNumber),
        isSortable: true,
        sortDirection: sortByEmployeeNumberAsc ? 'asc' : 'desc',
        onClick: () => {
          sortRowsByCode()
        },
      },
      { label: t(employeesTranslation.table.header.childs) },
      { label: t(employeesTranslation.table.header.kindergarten) },
      { label: t(employeesTranslation.table.header.costCenter) },
    ],
  }

  const sortRowsByCode = () => {
    setSortByEmployeeNumberAsc(!sortByEmployeeNumberAsc)
  }

  const getEmployees = (): void => {
    startLoading()
    EmployeesService()
      .GetEmployeesNursery(query)
      .then(response => {
        const employeesMapped: EmployeesTGDOrderModel[] = []
        response.data.rows.forEach(row => {
          row.userSons?.forEach(child => {
            employeesMapped.push(TGDEmployeesNurseryMap(row, child))
          })
        })
        const employeesWhichChildsWereNotAddedToOrder = employeesMapped.filter(emp => {
          return !tgdOrders.some(order => {
            return order.childs.some(c => c.userId === emp.userSon.userId)
          })
        })
        setEmployeesTGD(employeesWhichChildsWereNotAddedToOrder)
        setTotalRows(response.data.totalRows)
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const search = (filters: EmployeesNurseryRequest): void => {
    setQuery({
      ...filters,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const formSchema = Yup.object({
    productCode: Yup.number(),
    userNameOrDNIFilter: Yup.string(),
  })

  const form = useForm<EmployeesNurseryRequest>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      productCode: edenredProducts.ticketGuarderia,
      userNameOrDNIFilter: '',
    },
  })

  const onSearch = (): void => {
    const filters: EmployeesNurseryRequest = {
      productCode: edenredProducts.ticketGuarderia,
      userNameOrDNIFilter: form.getValues('userNameOrDNIFilter'),
    }
    search(filters)
  }

  const setEmployeeAndChild = (
    employee: EmployeesTGDOrderModel
  ): TGDEmployeeAndChildsModel => {
    const childs: TGDChildModel[] = []
    childs.push({
      firstName: employee.userSon.userFirstName,
      lastName: employee.userSon.userLastName,
      lastName2: employee.userSon.userLastName2,
      birthDate: employee.userSon.userBirthdayDate,
      kindergartenId: employee.userSon.lastNurseryId,
      firstName2: '',
      userId: employee.userSon.userId,
    })
    const employeeContext: TGDEmployeeAndChildsModel = {
      userId: employee.userId,
      birthDate: employee.userBirthdayDate,
      costCenter: employee.costCenter,
      document: employee.userDni,
      documentTypeId: employee.documentTypeId.toString(),
      email: employee.userEmail,
      employeeNumber: employee.employeeNumber,
      firstSurname: employee.userLastName,
      secondSurname: employee.userLastName2,
      name: employee.userFirstName,
      telephone: employee.userTelephone,
      existingChilds: childs,
      childs: [],
      companyId: 0,
      tgdOrderType: tgdOrderType.assignmentOnly,
      id: createOrderId(),
    }
    return employeeContext
  }

  const addChilds = (employees?: EmployeesTGDOrderModel[]): void => {
    if (employees && employees.length <= 0) return
    const arrayEmployees: TGDEmployeeAndChildsModel[] = []
    employees?.forEach(employee => {
      arrayEmployees.push(setEmployeeAndChild(employee))
    })
    navigate(navigationRoutes.tgdOrderEmployeesConfiguration)
  }

  const onSelectEmployee = (
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesTGDOrderModel
  ): void => {
    if (event.target.checked) {
      const exist = selectedEmployees.find(
        emp => emp.userSon.userId == employee.userSon.userId
      )
      if (!exist) {
        setSelectedEmployees(prev => [...prev, employee])
      }
    } else {
      setSelectedEmployees(
        selectedEmployees.filter(emp => emp.userSon.userId !== employee.userSon.userId)
      )
    }
  }

  const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedArray: EmployeesTGDOrderModel[] = []
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      const difference = getDiffBetweenEmployeeArrays(employeesTGD, selectedEmployees)
      const differenceWithoutOlderThan6 = difference.filter(emp => {
        const birthDate = new Date(emp.userSon.userBirthdayDate)
        const today = new Date()
        const diff = today.getFullYear() - birthDate.getFullYear()
        return diff <= 6
      })
      selectedArray.push(...differenceWithoutOlderThan6)
      if (selectedArray.length > 0)
        setSelectedEmployees(prev => [...prev, ...selectedArray])
    } else {
      selectedArray.push(
        ...selectedEmployees.filter((emp: EmployeesTGDOrderModel) => {
          return !employeesTGD.some((e: EmployeesTGDOrderModel) => {
            return emp.userSon.userId === e.userSon.userId
          })
        })
      )
      setSelectedEmployees([...selectedArray])
    }
  }

  const isSelected = (employee: EmployeesTGDOrderModel): boolean => {
    const exist = selectedEmployees.find(
      emp => emp.userSon.userId == employee.userSon.userId
    )
    return !!exist
  }

  useEffect(() => {
    if (!showSelected) getEmployees()
  }, [query])

  useEffect(() => {
    setTGDEmployeesToConfig(selectedEmployees)
    if (selectedEmployees.length === 0) setShowSelected(false)
  }, [selectedEmployees])

  useEffect(() => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
  }, [sortByEmployeeNumberAsc])

  return {
    header,
    totalRows,
    employeesTGD,
    page,
    setPage,
    pageSize,
    onPageChange,
    search,
    onEnter,
    form,
    onSearch,
    selectedEmployees,
    setSelectedEmployees,
    addChilds,
    onSelectEmployee,
    isSelected,
    showSelected,
    setShowSelected,
  }
}
