import React, { useState } from 'react'
import { Table } from '../../../Edenred'
import { CardOrderHomeForm } from '../../cardOrderHome/organism'
import { useUnloadHomeController } from '../hook/useUnloadHomeController'
import { useTranslation } from 'react-i18next'
import { unloadTranslation } from '../../../../../domain/translations'
import { CardOrderHomeFooter } from '../../cardOrderHome/molecules'
import { LeaveOrderPrompt } from 'src/presentation/components/Edenred/navigation'
import { MessagesModel } from 'src/domain/models'
import { OrderValidationErrorStep } from 'src/Flex/Orders/ui/order-validation'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'

const Container = () => {
  const { rows, header, footer, setRows, tableEmpty, caption } = useUnloadHomeController()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [errorData, setErrorData] = useState<MessagesModel[]>()
  const [excelError, setExcelError] = useState<string>('')
  return (
    <>
      {!errorData ? (
        <Table
          header={header}
          rows={rows}
          setRows={setRows}
          selector={{ buttonTitle: '', description: '', selectors: [], title: '' }}
          footer={footer()}
          tableEmpty={tableEmpty}
          tableBodyValign="middle"
          caption={caption}
          confirmDelete={true}
          confirmDeleteTitle={t(unloadTranslation.tableAction.delete.title)}
          confirmDeleteDescription={t(unloadTranslation.tableAction.delete.description)}>
          <>
            {rows.length > 0 ? (
              <CardOrderHomeForm
                setErrorData={setErrorData}
                setExcelError={setExcelError}
              />
            ) : (
              <CardOrderHomeFooter />
            )}
          </>
        </Table>
      ) : (
        <OrderValidationErrorStep
          errors={errorData}
          excelError={excelError}
          back={() => {
            setErrorData(undefined)
            setExcelError('')
            navigate(navigationRoutes.unloadHome)
          }}
        />
      )}

      <LeaveOrderPrompt />
    </>
  )
}

export default Container
