import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  allowedAccessClient,
  clientContract,
  indexDeliveryPoints,
  rechargeOrderById,
  singleClientData,
  singleDeliveryPointById,
  updateActiveClient,
} from '../../../config/constants/endpoints'
import type {
  AccountModel,
  DeliverySiteModel,
  HttpModel,
  IncompleteOrderModel,
  UserContract,
} from '../../../domain/models'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { axiosFetch } from '../../../infrastructure/axios'
import { UserContext } from './UserContext'
import { usePageViews } from 'src/presentation/hooks/usePageViews'
import { MultiCIFModel } from 'src/domain/models/user/userMultiCIF'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [contracts, setContracts] = useState<UserContract[]>([])
  const [clientsMultiCIF, setClientsMultiCIF] = useState<MultiCIFModel[]>([])
  const [company, setCompany] = useState<AccountModel>()
  const { handleMetaResponse } = useMetaResponseHandler()
  usePageViews()

  const userContract = useCallback(async () => {
    try {
      const { data: contracts } = await axiosFetch(clientContract, HttpMethod.GET, {})
      setContracts([...contracts])
      const { data: clients, meta } = await axiosFetch(
        allowedAccessClient,
        HttpMethod.GET,
        {}
      )
      handleMetaResponse(meta, undefined, { notifySuccess: false })
      setClientsMultiCIF([...clients])
      localStorage.setItem('refresh', 'false')
    } catch (error: any) {
      //TODO: Ver que hacer si devuelve un error
    }
  }, [contracts])

  const userCompany = useCallback(async () => {
    try {
      const { data: companyResponse } = await axiosFetch(
        singleClientData,
        HttpMethod.GET,
        {}
      )
      setCompany({ ...companyResponse })
    } catch (error: any) {
      //TODO: Ver que hacer si devuelve un error
    }
  }, [company])

  const userDeliverySites = useCallback(
    async (productType: number): Promise<HttpModel<DeliverySiteModel[]> | undefined> => {
      let deliverySites: HttpModel<DeliverySiteModel[]> | undefined
      try {
        const { url, method }: HttpRequest = {
          url:
            indexDeliveryPoints +
            '?productCode=' +
            productType +
            '&companyAdress=true&particularAdress=true&otherAdress=true',
          method: HttpMethod.GET,
        }
        const { data: httpResponse } = await axiosFetch(url, method, {})
        deliverySites = httpResponse
      } catch (error: any) {
        //TODO: Ver que hacer si devuelve un error
      }
      return deliverySites
    },
    []
  )

  const userDeliverySitesById = useCallback(
    async (deliverySiteId: number): Promise<DeliverySiteModel | undefined> => {
      let deliverySite: DeliverySiteModel | undefined
      try {
        const { url, method, body }: HttpRequest = {
          url: singleDeliveryPointById,
          body: { deliveryPointId: deliverySiteId },
          method: HttpMethod.POST,
        }
        const { data: httpResponse } = await axiosFetch(url, method, body)
        deliverySite = httpResponse
      } catch (error: any) {
        //TODO: Ver que hacer si devuelve un error
      }
      return deliverySite
    },
    []
  )

  const userRechargeOrderById = useCallback(
    async (orderId: number): Promise<IncompleteOrderModel | undefined> => {
      let cardOrder: IncompleteOrderModel | undefined
      try {
        const { url, method, body }: HttpRequest = {
          url: rechargeOrderById + orderId.toString(),
          method: HttpMethod.GET,
        }
        const { data: httpResponse } = await axiosFetch(url, method, {})
        cardOrder = httpResponse
      } catch (error: any) {
        //TODO: Ver que hacer si devuelve un error
      }
      return cardOrder
    },
    []
  )

  const updateMultiCIF = async (code: number): Promise<boolean> => {
    return await axiosFetch(updateActiveClient, HttpMethod.POST, {
      activeClientCode: code,
    })
      .then((result: any) => {
        setClientsMultiCIF(result)
        return true
      })
      .catch((error: any) => {
        return false
      })
  }

  const value = useMemo(
    () => ({
      contracts: contracts,
      userContract,
      company: company,
      userCompany,
      userDeliverySites,
      userDeliverySitesById,
      userRechargeOrderById,
      clientsMultiCIF,
      updateMultiCIF,
    }),
    [
      contracts,
      userContract,
      company,
      userCompany,
      userDeliverySites,
      clientsMultiCIF,
      updateMultiCIF,
    ]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
export const useUser = () => useContext(UserContext)
