import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { UploadExcelStepProps } from '.'
import { UploadExcelStep } from '.'
import { edenredProducts, orderType } from '../../../../../domain/enum'
import { SimpleForm } from '../../../../Templates'
import { unloadTranslation } from '../../../../../domain/translations'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { useEffect } from 'react'

export const OrderUnloadExcelPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setOrderContext } = useCardOrder()

  const uploadExcelStepProps: UploadExcelStepProps = {
    product: edenredProducts.ticketRestaurant,
  }

  useEffect(
    () => setOrderContext({ isExcelOrder: true, orderType: orderType.Unload }),
    []
  )

  return (
    <div>
      <SimpleForm
        title={t(unloadTranslation.title)}
        organisms={[<UploadExcelStep {...uploadExcelStepProps} />]}
      />
    </div>
  )
}
