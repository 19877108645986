import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  KeyboardEvent,
  ChangeEvent,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import {
  cardRequestType,
  edenredProducts,
  lastCardStatus,
  orderType,
} from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useNavigate } from 'react-router-dom'
import { OreCheckbox } from '@runroom/oreneta'
import { ConvertESDateToISO, GetDateFormatted } from 'src/core/helpers'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useTTOrder } from '../../context/TTOrderProvider'
import { EmployeesRequestBody } from 'src/domain/bodyRequest/EmployeesRequestBody'
import { DeliverySiteModel, EmployeesWithLastCard } from 'src/domain/models'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { cardOrderService } from 'src/core/services'
import { getEmployeesWithLastCardMap_v2 } from 'src/domain/adapters/cardOrder/getEmployeesWithLastCardMap'
import { EmployeesService } from 'src/core/services/employeesService'

export interface TTMultipleEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTT: EmployeesWithLastCard[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  search(filter: EmployeesRequestBody): void
  onEnter: (event: KeyboardEvent<HTMLInputElement>) => void
  form: UseFormReturn<EmployeesRequestBody, any>
  onSearch: () => void
  selectedEmployees: EmployeesWithLastCard[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesWithLastCard[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCard
  ): void
  isSelected(employee: EmployeesWithLastCard): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
  goToConfig: () => void
  isInContext: (employee: EmployeesWithLastCard) => boolean
}

export const useTTOrderSelectMultipleEmployees = (): TTMultipleEmployeeState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [employeesTT, setEmployeesTT] = useState<EmployeesWithLastCard[]>([])
  const {
    setTTSelectedEmployees,
    getTTSelectedEmployees,
    setTTEmployeesToConfig,
    ttOrders,
  } = useTTOrder()
  const navigate = useNavigate()
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeesWithLastCard[]>(
    getTTSelectedEmployees()
  )
  const [showSelected, setShowSelected] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [sortByEmployeeNumberAsc, setSortByEmployeeNumberAsc] = useState<boolean>(true)
  const { user } = useAuth()
  const previousSortedRef = useRef<boolean>(true)

  const defaultValues: EmployeesRequestBody = {
    productCode: edenredProducts.ticketTransporte,
    userNameOrDNIFilter: '',
    paginationModel: {
      orderByField: 'employeeNumber',
      sortDescending: !sortByEmployeeNumberAsc,
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  }

  const isInContext = (employee: EmployeesWithLastCard): boolean => {
    //Check by userId if exist employee or id if it's a new Employee by form
    const isInContext = ttOrders.find(order =>
      order.userId
        ? order.userId.toString() === employee.userId
        : order.id === employee.userId
    )
    return !!isInContext
  }

  const getDiffBetweenEmployeeArrays = (
    array1: EmployeesWithLastCard[],
    array2: EmployeesWithLastCard[]
  ): EmployeesWithLastCard[] => {
    let result: EmployeesWithLastCard[] = []
    result = array1.filter(emp1 => {
      return !array2.some(emp2 => {
        return emp1.userId === emp2.userId
      })
    })
    return result
  }

  const [query, setQuery] = useState<EmployeesRequestBody>({ ...defaultValues })

  const header: HeaderModel = {
    headerElement: (
      <OreCheckbox
        name="check"
        checked={
          selectAll &&
          getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees).length <= 0
        }
        onChange={event => {
          onSelectAllEmployees(event)
        }}
        disabled={showSelected}
      />
    ),
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      {
        label: t(employeesTranslation.table.header.employeeNumber),
        isSortable: true,
        sortDirection: sortByEmployeeNumberAsc ? 'asc' : 'desc',
        onClick: () => {
          sortRowsByCode()
        },
      },
      { label: t(employeesTranslation.table.header.balance) },
      { label: t(employeesTranslation.table.header.cardStatus) },
      { label: t(employeesTranslation.table.header.expired) },
    ],
  }

  const sortRowsByCode = () => {
    setSortByEmployeeNumberAsc(!sortByEmployeeNumberAsc)
  }

  const getEmployees = (): void => {
    startLoading()
    cardOrderService()
      .getEmployeesWithLastCardDuplicates(query)
      .then(response => {
        const result = getEmployeesWithLastCardMap_v2(response.data.rows)
        setEmployeesTT(result)
        setTotalRows(response.data.totalRows)
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const search = (filters: EmployeesRequestBody): void => {
    setQuery({
      ...filters,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const formSchema = Yup.object({
    productCode: Yup.number(),
    userNameOrDNIFilter: Yup.string(),
  })

  const form = useForm<EmployeesRequestBody>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      productCode: edenredProducts.ticketTransporte,
      userNameOrDNIFilter: '',
    },
  })

  const onSearch = (): void => {
    const filters: EmployeesRequestBody = {
      productCode: edenredProducts.ticketTransporte,
      userNameOrDNIFilter: form.getValues('userNameOrDNIFilter'),
    }
    search(filters)
  }

  const mapEployee = (employee: EmployeesWithLastCard): TTOrderModel => {
    const ttOrderModel: TTOrderModel = {
      id: employee.userId,
      birthDate: ConvertESDateToISO(employee.birthDate),
      companyId: user?.code ? +user.code : 0,
      corporativeEmail: employee.corporativeEmail,
      costCenter: employee.costCenter,
      document: employee.document,
      documentTypeId: employee.documentTypeId,
      employeeNumber: employee.employeeNumber,
      name: employee.userName,
      telephone: employee.corporativeTelephone,
      userId: +employee.userId,
      firstSurname: '',
      secondSurname: '',
      deliverySiteId: employee.deliverySiteId,
      orderTypeId: orderType.Card,
      deliverySiteData: {} as DeliverySiteModel,
      cardData: {
        initialAmount: 0,
        productTypeId: edenredProducts.ticketTransporte,
        balance: employee.balance.toString(),
        cardRequestType: cardRequestType.duplicated,
        cardRequestTypeId: 0, //this field is setted on tt order config
        expiredDate: GetDateFormatted(employee.expiredDate),
      },
    }
    return ttOrderModel
  }

  const goToConfig = (): void => {
    if (selectedEmployees && selectedEmployees.length <= 0) return
    const arrayEmployees: TTOrderModel[] = []
    selectedEmployees.forEach(selectedEmployee => {
      arrayEmployees.push(mapEployee(selectedEmployee))
    })
    setTTEmployeesToConfig(arrayEmployees)
    navigate(navigationRoutes.ttOrderEmployeesConfiguration)
  }

  const onSelectEmployee = (
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCard
  ): void => {
    if (event.target.checked) {
      const exist = selectedEmployees.find(emp => emp.userId == employee.userId)
      if (!exist) {
        setSelectedEmployees(prev => [...prev, employee])
      }
    } else {
      setSelectedEmployees(
        selectedEmployees.filter(emp => emp.userId !== employee.userId)
      )
    }
  }

  const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedArray: EmployeesWithLastCard[] = []
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      let difference = getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees)
      difference = difference.filter(emp => emp.cardStatusId >= +lastCardStatus.confirmed)
      selectedArray.push(...difference)
      if (selectedArray.length > 0)
        setSelectedEmployees(prev => [
          ...prev,
          ...selectedArray.filter(emp => !isInContext(emp)),
        ])
    } else {
      selectedArray.push(
        ...selectedEmployees.filter((emp: EmployeesWithLastCard) => {
          return !employeesTT.some((e: EmployeesWithLastCard) => {
            return emp.userId === e.userId
          })
        })
      )
      setSelectedEmployees([...selectedArray.filter(emp => !isInContext(emp))])
    }
  }

  const isSelected = (employee: EmployeesWithLastCard): boolean => {
    const exist = selectedEmployees.find(emp => emp.userId == employee.userId)
    return !!exist
  }

  useEffect(() => {
    if (!showSelected) {
      getEmployees()
    }
  }, [query])

  useEffect(() => {
    setTTSelectedEmployees(selectedEmployees)
    if (selectedEmployees.length === 0) setShowSelected(false)
  }, [selectedEmployees])

  useEffect(() => {
    if (previousSortedRef.current !== sortByEmployeeNumberAsc) {
      previousSortedRef.current = sortByEmployeeNumberAsc

      setQuery({
        ...query,
        paginationModel: {
          orderByField: 'employeeNumber',
          sortDescending: !sortByEmployeeNumberAsc,
          numberOfRecordsPerPage: pageSize,
          pageNumber: page - 1,
        },
      })
    }
  }, [sortByEmployeeNumberAsc])

  return {
    header,
    totalRows,
    employeesTT: employeesTT,
    page,
    setPage,
    pageSize,
    onPageChange,
    search,
    onEnter,
    form,
    onSearch,
    selectedEmployees,
    setSelectedEmployees,
    onSelectEmployee,
    isSelected,
    showSelected,
    setShowSelected,
    goToConfig,
    isInContext,
  }
}
