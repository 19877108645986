export enum RelationShipEnum {
  defaultRelationShip = 0,
  son = 1,
  couple = 2,
  sibling = 3,
  parent = 4,
  other = 5,
}

export interface EmployeeProductListModel {
  ticketRestaurant: TicketRestaurantProductModel | undefined
  ticketTransporte: MovilidadProductModel | undefined
  ticketGuarderia: GuarderiaProductModel[] | undefined
  formation: TrainingProductModel[] | undefined
  healthInsurance: HealthInsuranceProductModel[] | undefined
  externalHealthInsurance: ExternalHealthInsuranceProductModel[] | undefined
}

export interface TicketRestaurantProductModel {
  id: string
  isVirtual?: boolean
  contractDate?: string
  startDate?: string
  monthsAndAmounts?: MonthsAndAmounts[]
}

export interface MonthsAndAmounts {
  month: string
  amount: number
}

export interface MovilidadProductModel {
  id: string
  isVirtual?: boolean
  contractDate?: string
  startDate?: string
  monthsAndAmounts?: MonthsAndAmounts[]
}

export interface GuarderiaProductModel {
  id: string
  childrenName?: string
  birthDate?: string
  kindergarten?: string
  startDate?: string
  contractDate?: string
  monthsAndAmounts?: MonthsAndAmounts[]
  anualContractAmount?: number
}

export interface TrainingProductModel {
  id: string
  trainingName?: string
  schoolName?: string
  contractDate?: string
  startDate?: string
  totalAmount?: number
  splitPayment?: number
  pendingAmount?: number
}

export interface HealthInsuranceProductModel {
  id: string
  name?: string
  isBeneficiary?: boolean //true = beneficiaro, false = tomador
  relativeType?: number //RelationShipEnum
  company?: string
  type?: string //Modalidad
  monthAmount?: number
  contractDate?: string
  startDate?: string
}

export interface ExternalHealthInsuranceProductModel {
  id: string
  name?: string
  isBeneficiary?: boolean //true = beneficiaro, false = tomador
  relativeType?: number //RelationShipEnum
  company?: string
  policyName?: string
  monthAmount?: number
  startDate?: string
  contractDate?: string
}
