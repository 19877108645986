// API backend values
export const orderType = {
  Undefined: -1,
  Default: 0,
  Card: 1, // cards and duplicated
  Recharge: 2,
  Unload: 100,
  NurseryAssignmentOrder: 101,
}

// API backend values
export const excelRequestType = {
  none: 0,
  CardsOptionalRecharge: 66, // cortex id
  Recharge: 67, // cortex id
  Unload: 68, // cortex id,
  TgdOrder: 73,
  TTOrderCard: 74,
}

export enum documentType {
  Default = 0,
  DniNie = 1,
  Passport = 2,
}

export enum cardType {
  Plastic = 2,
  Virtual = 7,
}

export const cardRequestType = {
  newCard: 1,
  charge: 2,
  duplicated: 3,
}

export enum salarySacrificeType {
  default = 1,
}

export enum currency {
  euro = '€',
  dollar = '$',
}

export enum emptyValues {
  dash = '-',
  zero = '0',
  empty = '',
  none = 'N/A',
}

export enum cardRequestTypeId {
  default = 0,
  credit = 1,
  newPlastic = 2,
  lostStolenPlastic = 3, //***
  stolen = 4, //not used
  damagePlastic = 5,
  renewal = 6,
  newPlasticless = 7,
  lostStolenPlasticless = 8, //perdida o robo
  renewalPlasticless = 9,
  damagePlasticless = 10, // daño
}

export enum cardOrderReason {
  default = 0,
  stolenLost = 1,
  damage = 2,
}

export enum myOrdersTabs {
  lastOrders = 0,
  incompleteOrders = 1,
}

export enum orderStatus {
  placed = 3,
  requested = 1,
  cancelled = -1,
  inProcess = 2,
}

export enum excelAlert {
  warning = 'WARN',
  error = 'ERROR',
}

export enum cardOrderTypeEnum {
  default = 0,
  newAndDuplicate = 1,
  recharge = 2,
  discharge = 100,
  assignment = 101,
}

export enum OSCCode {
  recharge_1 = 4,
  recharge_2 = 5,
  unload_1 = 14,
  unload_2 = 15,
}

export type OrderContext = {
  isExcelOrder: boolean // true if the order is created from excel
  orderType: number // form types
}

export const orderUndefined: OrderContext = {
  isExcelOrder: false,
  orderType: orderType.Undefined,
}

export const formNewCardsOrDuplicate: OrderContext = {
  isExcelOrder: false,
  orderType: orderType.Card,
}

export const formRecharge: OrderContext = {
  isExcelOrder: false,
  orderType: orderType.Recharge,
}

export const formUnload: OrderContext = {
  isExcelOrder: false,
  orderType: orderType.Unload,
}

export const excelCard: OrderContext = {
  isExcelOrder: true,
  orderType: orderType.Card,
}

export const excelRecharge: OrderContext = {
  isExcelOrder: true,
  orderType: orderType.Recharge,
}

export const excelUnload: OrderContext = {
  isExcelOrder: true,
  orderType: orderType.Unload,
}

export const proformaExcelType = {
  card: 1,
  credit: 2, //recharge
  nursery: 3, //tgd
}

export const allowMobilePlasticless = {
  pending: null,
  noCompatible: false,
  compatible: true,
}

export const lastCardStatus = {
  logicDestrucction: -1,
  requested: 1,
  sent: [2, 5],
  rejected: 3,
  confirmed: 4,
  activationResquested: 6,
  active: 7,
  lockRequested: 8,
  locked: 9,
  unlockRequested: 10,
  cancelRequested: 11,
  cancelled: [12, 13, 18, 19, 20, 21],
  activationRejected: 14,
  lockRejected: 15,
  unlockRejected: 16,
  cancellationRejected: 17,
}
