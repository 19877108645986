/* naming convention
Basic CRUD:
index  <- GET all items + endpoint name
single <- GET single item by id + endpoint name
create <- POST an item + endpoint name
update <- PUT data to an item + endpoint name
remove <- DELETE an item + endpoint name
crud <- create / update / remove crud + endpointName

Specific:
validate + enpoint name
singleBy + endpoint name
removeAll + endpoint name
*/

// Old kentico site URL
export const loginSiteUrl: string = process.env.REACT_APP_LOGIN_SITE_URL
  ? process.env.REACT_APP_LOGIN_SITE_URL
  : ''
export const logoutSiteUrl: string = process.env.REACT_APP_LOGOUT_URL
  ? process.env.REACT_APP_LOGOUT_URL
  : ''

// delivery site
export const indexDeliveryPoints = 'client/ClientDeliveryPoints'
export const createDeliverySite = 'client/AddDeliveryPoints'
export const updateDeliverySite = 'client/UpdateDeliveryPoints'
export const singleDeliveryPointById = 'client/GetDeliveryPointById'
export const singleByLastDeliveryTypeProductCode = 'client/DeliveryType?productCode='
export const removeDeliveryPoint = '/client/DeleteDeliveryPoints'
export const updateFavoriteDeliveryPoint = '/client/UpdateFavoriteDeliveryPoint'

// excels
export const indexOrderTemplate = '/Client/GetOrderTemplate'
export const validateOrderExcel = '/Client/ValidateOrderExcel'
export const createOrderExcel = '/Client/LoadOrderExcel'

//spending rule
export const indexSpendingRule = 'client/GetUsageProfilesGlobal'
export const singleSpendingRuleById = 'client/UsageProfileGlobal?usageProfileId='
export const createSpendingRule = 'client/UsageProfileGlobal'
export const updateSpendingRule = 'client/UpdateUsageProfileGlobal'
export const setFavSpendingRule = '/Client/SetDefaultUsageProfile'
export const removeSpendingRule = 'client/DeleteUsageProfileGlobal'
export const crudSpendingRule = 'client/UsageProfile'

// my account
export const logout = '/Identity/User/RevokeToken'
export const singleClientData = 'client/GetClientData'
export const updateClientData = 'client/UpdateClientData'
export const singleUserData = 'Identity/UserData'
export const updateUserData = 'client/UpdateClientUserData'
export const updatePassword = 'User/ChangePasswordClear'
export const getPermissionTags = '/Identity/UserPolicies/WebTagsPermissions'
export const updateClientEmail = '/Identity/UpdateUserEmail'
export const changePassword = '/Identity/UserClient/ChangePassword'
export const deleteAccount = '/Identity/UserClient/Delete'
export const setReceiveInformation = '/Identity/UserClient/UpdateOptin'
export const getReceiveInformation = '/Identity/UserClient/GetOptin'
export const downloadCancellationTerms = '/Client/Documents/CancellationTerms'
export const downloadLegalDocument = '/Client/Documents/LegalDocument'
export const usersAndLicenses = '/Identity/UserClient/UsersWithRolAssignment'
export const createUserAndLicenses = '/Identity/UserClient/CreateUserWithRoleAssignment'
export const editUserAndLicenses = '/Identity/UserClient/UpdateUserRoleAssignment'
export const deleteUserAndLicenses = '/Identity/UserClient/DeleteClientUserManager'

// Role Standarization
export const getDataControllerContext = '/Client/AutoEnrollment/DataControllerContext'
export const beginDocumentSigning = '/Client/AutoEnrollment/BeginDocumentsSigning'
export const getAutoEnrollmentLogaltyStatus = '/Client/AutoEnrollment/VerifySigningEnding'

// card order
export const validateCardOrder = 'Client/CardOrderValidation/NewAndDuplicate'
export const createCardOrderNewDuplicated = '/Client/CardOrder/NewAndDuplicate'
export const createCardOrderRecharge = '/Client/CardOrder/Recharge'
export const createCardOrderUnload = '/Client/CardOrder/Discharge'
export const saveCardOrder = '/Client/CardPreOrder'
export const updateLastOrderFav = '/Client/CardOrderFav'
export const singleGetNewDuplicatedCheckout = '/Client/CardOrderSummary/NewAndDuplicate'
export const singleGetRechargeCheckout = '/Client/CardOrderSummary/Recharge'
export const singleGetUnloadCheckout = '/Client/CardOrderSummary/Discharge'
export const singleGetCardDetail = '/Client/CardOrderDetails'
export const singleGetPaymentMethod = '/Client/Payments?productCode='
export const employeeWithLastCard = '/Client/Duplicate/EmployeesWithLastCard?productCode='

export const employeeWithLastCardDuplicate =
  '/Client/Duplicate/EmployeesWithLastCardAndBalance'
export const employeeWithLastCardRecharge =
  '/Client/Recharge/EmployeesWithLastCardAndBalance'
export const employeeWithLastCardUnload =
  '/Client/Discharge/EmployeesWithLastCardAndBalance'

export const singleGetLastOrders = '/Client/CardOrderHistoric'
export const singleGetIncompleteOrders = '/Client/CardPreOrder'
export const singleGetLastOrderProforma = '/Client/CreateProformaExcel'
export const deletePreOrder = '/Client/DeleteCardPreOrder'
export const singleGetInvoiceDocument = '/Client/Invoice'
export const rechargeOrderById = '/Client/CardOrder/Repeat/Recharge?orderId='
//User data
export const clientContract = '/Client/GetClientContract'
export const allowedAccessClient = '/Client/AllowedAccessClients'
export const updateActiveClient = '/Client/SetActiveClient'

// Invoices
export const invoicesList = '/Client/InvoiceHistoric'
export const creditNotesList = '/Client/Invoice/CreditNotes'

// Employees
export const singleGetEmployeeWithLastCard = '/Client/EmployeesWithLastCard'
export const updateGetEmployeeWithLastCard = '/Client/UpdateClientEmployee'
export const updateEmployeeWithLastCardUsageProfile =
  '/Client/UpdateClientEmployeeRestrictionProfile'
export const updateEmployeeSon = '/Client/UpdateClientEmployeeSon'
export const isClientEmployeeFlex = '/Client/IsClientEmployeeFlex'
export const deleteEmployees = '/Client/DeleteClientEmployees'
export const deleteEmployeeByDocument = '/Client/DeleteClientEmployeeByDocumentId'
export const downloadEmployeeExcel = '/Client/EmployeesWithLastCard/Excel'
export const downloadEmployeeTTExcel = '/Client/EmployeesWithLastCardMobility/Excel'
export const getEmployeesNursery = '/Client/ClientEmployeesNursery'
export const getEmployeesTGDExcel = '/Client/ClientEmployeesNursery/Excel'
export const getEmployeeProducts = '/Client/ClientEmployeeProducts'

// Merchant
export const getKindergartenSearch = '/Client/Merchant/NurserySearch'

// Employee Card
export const enableDisableEmployeeCard = '/Client/EmployeeCard/EnableDisable'

//GeneralTerms
export const singleGeneralTerms = '/Client/GeneralConditions'

// History reports
export const usersConsumption = '/Client/UsersConsumption'
export const usersConsumptionExcel = '/Client/UsersConsumption/Excel'
export const usersAccountOperations = '/Client/UsersAccountOperations'
export const usersAccountOperationsExcel = '/Client/UsersAccountOperations/Excel'
export const dietSpendings = '/Client/UsersConsumption/Dietas'
export const dietSpendingsExcel = '/Client/UsersConsumption/Dietas/Excel'
export const assignmentHistory = '/Client/UsersNurseryAssignments'
export const assignmentHistoryExcel = '/Client/UsersNurseryAssignments/Excel'

// Flex
export const getFlexInitialData = '/Client/InitialData'

export const indexProductList = '/Client/Products'
export const getProductConfiguration = '/Client/Products/{productType}/Configuration'
export const getIsaludInsurance = '/Client/Products/ISalud/Insurance'
export const getIsaludInsuranceConditions =
  '/Client/Products/ISalud/Insurance/PolicyConditions?id='
export const saveProductConfiguration = '/Client/Products/{productType}/Configuration'
export const saveProductState = '/Client/Products/{productType}/EnableDisable'
export const saveChangesPolicies =
  '/Client/Products/ExternalHealthInsurance/Configuration/Policy'
export const deleteExternalHealthPolicy =
  '/Client/Products/ExternalHealthInsurance/Configuration/Policy?id='

export const indexEmployeeList = '/Client/Employees'
export const massiveDeleteEmployee = '/Client/Employees/MassiveDelete'
export const getEmployeeConfiguration = '/Client/Employees/EmployeeById'
export const getEmployeeProductList = '/Client/Employees/Products'
export const deleteEmployeeProductList = '/Client/Employees/Products'
export const saveEmployeeState = '/Client/Employees/{employeeId}/EnableDisable'
export const getEmployeeFlexibleRenumeration =
  '/Client/EmployeeCalculations/AmountAvailableInFlexibleRemuneration'
export const employeeDownloadExcel = '/Client/Employees/MassActionsExcel'
export const employeeDownloadEmptyExcel = '/Client/Employees/MassActionsExcel/Template'
export const employeeValidateExcel = '/Client/Employees/ValidateMassActionsExcel'
export const employeeConfirmExcel = '/Client/Employees/MassActionsConfirmExcel'
export const employeeConfirmContractReceived =
  '/Client/Employees/{employeeId}/AcceptNovationDocument'
export const employeeDownloadDocument =
  '/Client/Employees/{employeeId}/EmployeeNovacionContract/?documentType={documentType}'

export const indexCollectiveList = '/Client/Collectives'
export const getCollectiveConfiguration = '/Client/Collectives/CollectiveById'
export const getCollectiveProductList = '/Client/Collectives/{collectiveId}/Products'
export const getCollectiveProductConfiguration =
  '/Client/Collectives/{collectiveId}/Products/{productType}/Configuration'
export const saveCollectiveProductConfiguration =
  '/Client/Collectives/{collectiveId}/Products/{productType}/Configuration'
export const saveCollectiveProductState =
  '/Client/Collectives/{collectiveId}/Products/{productType}/EnableDisable'
export const getCollectiveIsaludConfiguration =
  '/Client/Collectives/{collectiveId}/Products/ISalud/Insurance/Configuration'
export const saveCollectiveIsaludConfiguration =
  '/Client/Collectives/{collectiveId}/Products/ISalud/Insurance/Configuration'
export const indexProductsAdherenceList = '/Client/AccessionProcess'
export const indexProductsAdherenceAcceptRejectedList =
  '/Client/AccessionProcess/AcceptOrReject'
export const getPolicyIcons =
  '/Client/Products/ISalud/Insurance/PolicyIcons?id={policyId}'
export const indexEmployeesLastAdherenceList = '/Client/AccessionEmployeesLastMonth'
export const indexEmployeesPendingAdherenceList = '/Client/PendingAccessionEmployees'

export const getImputationOrders = '/Client/ImputationProcess'
export const getRefreshImputations =
  '/Client/ImputationProcess?afterCutoffDate=true&productType={productType}'
export const sammuryImputations = '/Client/ImputationProcess/SummaryImputations'
export const confirmImputations = '/Client/ImputationProcess/ConfirmImputations'
export const imputeImputationOrders = '/Client/ImputationProcess/Impute'
export const deleteImputationOrder = '/Client/ImputationProcess/DeleteImputation'

export const indexCutOffDate = '/Client/CutoffDate'
export const getValidationOrder = '/Client/ValidationProcess'
export const calculateValidationOrder = '/Client/ValidationProcess/Calculate'
export const validateValidationOrder = '/Client/ValidationProcess/Validate'

export const indexTemplatesList = '/Client/EmployeeComunications/Templates'
export const getTemplateConfiguration = '/Client/EmployeeComunications/Template'
export const deleteTemplate = '/Client/EmployeeComunications/Template/{templateId}'
export const getAvailableTemplates = '/Client/EmployeeComunications/AvailableTemplates'
export const getAvailableTemplateVars =
  '/Client/EmployeeComunications/AvailableTemplateVars'
export const sendTemplate = '/Client/EmployeeComunications/SendEmail'
export const testTemplate = '/Client/EmployeeComunications/SendEmailTest'

export const getEmployeesPayrollList = '/Client/Employees/Payroll'
export const payrollDownloadExcel = '/Client/Employees/PayrollExcel?date='
export const payrollExcelConfiguration = '/Client/Employees/PayrollExcelConfiguration'

export const getPendingTasks = '/Client/TaskManager'
export const getPendingContractAccession = '/Client/TaskManager/PendingContractAccession'
export const sendPendingWelcomeEmails = '/Client/TaskManager/SendPendingWelcomeEmail'

export const downloadPlanReport = '/Client/PlanReport'

//TGD
export const validateTGDOrder = '/Client/OrderValidation/NurseryAssignment'
export const checkoutTGDOrder = '/Client/OrderSummary/NurseryAssignment'
export const completeTGDOrder = '/Client/OrderComplete/NurseryAssignment'
export const saveTGDOrder = '/Client/CardPreOrder'
export const downloadTGDExcel = '/Client/GetOrderTemplate'
export const repeatNurseryAssigmentOrder =
  '/Client/CardOrder/Repeat/NurseryAssignment?orderId='

//TT
export const downloadTTExcel = '/Client/GetOrderTemplate'
