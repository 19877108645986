import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { GetDeliverySitesRequestBody } from '../../../../../core/controllers/cardOrder/services'
import {
  deliverySiteService,
  getDeliverySitesByGroup,
  getDuplicateCardReason,
  getDuplicateCardType,
} from '../../../../../core/services'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type { RowModel } from '../../../../../domain/customComponents/table/TableModel'
import {
  cardOrderReason,
  cardRequestTypeId,
  cardType,
  currency,
  edenredProducts,
  emptyValues,
  orderType,
} from '../../../../../domain/enum'
import type { BasicParameters } from '../../../../../domain/forms'
import type { CardOrderModel, DeliverySiteModel } from '../../../../../domain/models'
import {
  duplicatesConfigurationTranslations,
  forms,
} from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useUser } from '../../../../context/user/UserProvider'
import { useAsync } from '../../../../hooks'

export interface CardOrderHomeTableState {
  onClickEdit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cardOrder: CardOrderModel
  ) => void
  orders: CardOrderModel[]
  setFormMode: (formParameters: BasicParameters<CardOrderModel>) => void
  getInitialImport: (balance: string | undefined) => string
}

export const useCardOrderHomeTableController = (): CardOrderHomeTableState => {
  const { setFormMode, orders, setConfigurationMode } = useCardOrder()
  const [deliverySitesOptions, setDeliverySitesOptions] = useState<ComboBoxOptionModel[]>(
    []
  )
  const navigate = useNavigate()
  const { startLoading, isLoading } = useLoader()
  const { contracts } = useUser()
  const currentContractTR = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )

  const { state: deliverySitesResponse } = useAsync<any>(() => {
    startLoading()
    return deliverySiteService().getAll(GetDeliverySitesRequestBody())
  })

  const GetSendDeliverySiteOptions = (deliverySites: DeliverySiteModel[]) => {
    const options: ComboBoxOptionModel[] = [...deliverySitesOptions]
    if (deliverySites) {
      const groupedOptions = [...getDeliverySitesByGroup(deliverySites)]
      groupedOptions.map(option => {
        options.push(option)
      })
    }
    setDeliverySitesOptions(options)
  }

  const onClickEdit = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      cardOrder: CardOrderModel
    ) => {
      event.preventDefault()
      if (
        cardOrder.orderTypeId === orderType.Card &&
        (cardOrder.cardData.cardRequestTypeId === cardRequestTypeId.newPlastic ||
          cardOrder.cardData.cardRequestTypeId === cardRequestTypeId.newPlasticless)
      ) {
        setFormMode({ entity: cardOrder, editMode: true })
        navigate(navigationRoutes.cardOrderEdit)
      }
      if (
        cardOrder.orderTypeId === orderType.Card &&
        (cardOrder.cardData.cardRequestTypeId === cardRequestTypeId.lostStolenPlastic ||
          cardOrder.cardData.cardRequestTypeId ===
            cardRequestTypeId.lostStolenPlasticless ||
          cardOrder.cardData.cardRequestTypeId === cardRequestTypeId.damagePlastic ||
          cardOrder.cardData.cardRequestTypeId === cardRequestTypeId.damagePlasticless)
      ) {
        if (cardOrder) {
          const duplicateRow: RowModel = {
            rowId: cardOrder.orderId,
            cells: [
              {
                type: 'text',
                cellText: [
                  {
                    text: formatString(cardOrder.employeeData.name),
                    name: 'name',
                    tone: 'neutral-900',
                    size: 'text-sm',
                    isBold: true,
                  },
                  {
                    text: formatString(cardOrder.employeeData.document),
                    name: 'idCard',
                    size: 'text-sm',
                  },
                ],
              },
              {
                type: 'textBox',
                cellControll: {
                  name: 'email',
                  value: formatString(cardOrder.employeeData.email),
                  type: 'email',
                  maxLength: 75,
                  errorValidate: ErrorEmailValidValue,
                  massageErrorValidate: MessageEmailValidValue,
                },
              },
              {
                type: 'comboBox',
                cellControll: {
                  name: 'reason',
                  options: getDuplicateCardReason(),
                  placeHolder: t(
                    duplicatesConfigurationTranslations.table.comboBoxFields.default
                  ),
                  value: GetReason(cardOrder.cardData.cardRequestTypeId),
                },
              },
              {
                type: 'comboBox',
                cellControll: {
                  name: 'type',
                  options: getDuplicateCardType(currentContractTR?.allowPlasticless),
                  placeHolder: t(
                    duplicatesConfigurationTranslations.table.comboBoxFields.default
                  ),
                  value: currentContractTR?.allowPlasticless
                    ? cardOrder.cardData.cardTypeId.toString()
                    : cardType.Plastic.toString(),
                  disabled: !currentContractTR?.allowPlasticless,
                },
              },
              {
                type: 'comboBox',
                cellControll: {
                  name: 'deliverySites',
                  options: deliverySitesOptions,
                  placeHolder: t(
                    duplicatesConfigurationTranslations.table.comboBoxFields.default
                  ),
                  value: cardOrder.sendData?.deliverySiteId.toString(),
                  group: true,
                },
              },
            ],
          }
          setConfigurationMode({ editMode: true, entity: [duplicateRow] })
          navigate(navigationRoutes.cardOrderDuplicateConfigurationEdit)
        }
      }
    },
    [deliverySitesOptions]
  )

  const GetReason = (requestType: number | undefined): string => {
    let reason = ''
    if (
      requestType === cardRequestTypeId.lostStolenPlastic ||
      requestType === cardRequestTypeId.lostStolenPlasticless
    )
      reason = cardOrderReason.stolenLost.toString()
    if (
      requestType === cardRequestTypeId.damagePlastic ||
      requestType === cardRequestTypeId.damagePlasticless
    )
      reason = cardOrderReason.damage.toString()
    return reason
  }

  const ErrorEmailValidValue = (email: string | undefined): boolean => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/

    if (email === '' || email === undefined) {
      return true
    }

    if (!email.match(mailformat)) {
      return true
    }

    return false
  }

  const MessageEmailValidValue = (email: string | undefined): string | undefined => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/

    if (email === '' || email === undefined) {
      return t(forms.errors.fieldRequired)
    }

    if (!email.match(mailformat)) {
      return t(forms.errors.invalidFormat)
    }

    return undefined
  }

  const formatString = (text: string | undefined): string => {
    if (text === undefined) return ''
    return text
  }

  const getInitialImport = (balance: string | undefined): string => {
    if (!balance) {
      return emptyValues.zero + currency.euro
    }
    return emptyValues.none
  }

  useEffect(() => {
    if (deliverySitesResponse?.rows.length > 0) {
      GetSendDeliverySiteOptions(deliverySitesResponse?.rows)
    }
  }, [deliverySitesResponse])

  return { onClickEdit, orders, setFormMode, getInitialImport }
}
