import { OreHeading } from '@runroom/oreneta'
import { useTranslation } from 'react-i18next'
import { OrderAndDuplicateStepsData } from '../components/Edenred/stepper'
import { useCardOrder } from '../context/cardOrder/CardOrderProvider'
import Stepper from '../components/Edenred/stepper/Stepper'
import { stepper } from '../../domain/enum'
import { cardOrderTypeExists, getCardOrderTypeLength } from '../../core/services'
import { LeaveOrderPrompt } from '../components/Edenred/navigation'
import { MessagesModel } from 'src/domain/models'
import { OrderValidationErrorStep } from 'src/Flex/Orders/ui/order-validation'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

interface Props {
  title: string
  elementsnoOrders: React.ReactNode[]
  elementsWithOrders: React.ReactNode[]
  errorData: MessagesModel[] | undefined
  excelError: string
  backToHome: () => void
}

export const CardOrderHomeTemplate = ({
  title,
  elementsnoOrders,
  elementsWithOrders,
  errorData,
  excelError,
  backToHome,
}: Props) => {
  const { orders } = useCardOrder()
  const { t } = useTranslation()
  return (
    <>
      <header className="width-max">
        <OreHeading as="h1" size="title-sm">
          {t(title)}
        </OreHeading>
        <Stepper
          currentStep={
            getCardOrderTypeLength(orders) > 0 ? stepper.secondStep : stepper.firstStep
          }
          stepsFor={OrderAndDuplicateStepsData}
          paddingBottom={40}
        />
      </header>
      {!errorData ? (
        <>
          {cardOrderTypeExists(orders)
            ? elementsWithOrders.map((organism: React.ReactNode, index: number) => (
                <section className="width-max" key={index}>
                  {organism}
                </section>
              ))
            : elementsnoOrders.map((organism: React.ReactNode, index: number) => (
                <section className="width-max" key={index}>
                  {organism}
                </section>
              ))}
        </>
      ) : (
        <OrderValidationErrorStep
          errors={errorData}
          excelError={excelError}
          back={backToHome}
        />
      )}
      <LeaveOrderPrompt />
    </>
  )
}
